import React from 'react'
import { Button, Box } from '../ui'

const ForwardButton = ({ children, ...props }) => (
  <Button
    variant="text"
    {...props}
  >
    {children}
    <Box
      ml={2}
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="inherit"
        d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
      />
    </Box>
  </Button>
)

export default ForwardButton
