import React from 'react'
import PageLayout from './PageLayout'
import { SidebarDefault } from '../sidebar'
import LayoutSection from './LayoutSection'

const PageLayoutDefault = ({ heading, children, ...props }) => (
  <PageLayout
    {...props}
    sideNode={<SidebarDefault />}
  >
    <LayoutSection
      headingAs="h1"
      heading={heading}
    >
      {children}
    </LayoutSection>
  </PageLayout>
)

export default PageLayoutDefault
