import React from 'react'
import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import { Box, Text } from '../ui'

const Container = styled(Box)`
  word-break: break-word;
`

const cutAfterNWords = (sentence = '', n) => {
  const wordArray = (sentence || '').split(' ')

  if (wordArray.length > n) {
    return `${wordArray.slice(0, n).join(' ')}...`
  }

  return sentence
}

const PostExcerpt = ({ children = '', maxWords = 24, ...props }) => (
  <Container
    as="p"
    {...props}
  >
    {HTMLReactParser(cutAfterNWords(children, maxWords))}
    <Text
      ml={1}
      as="span"
      fontWeight="bold"
    >
      Tovább
    </Text>
  </Container>
)

export default PostExcerpt
