import React from 'react'
import ForwardButton from './ForwardButton'

const ForwardLink = (props) => (
  <ForwardButton
    as="a"
    {...props}
  />
)

export default ForwardLink
