import React from 'react'
import Flex from './Flex'

const FlexCentered = (props) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    {...props}
  />
)

export default FlexCentered
