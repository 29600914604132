import React from 'react'
import { graphql } from 'gatsby'
import { PostList } from '../components/posts'
import { ForwardLink } from '../components/content'
import { PageLayoutDefault } from '../components/layout'
import { FlexCentered, Link, Heading } from '../components/ui'

const NotFoundPage = ({ data }) => (
  <>
    <FlexCentered
      p={4}
      mb={6}
      textAlign="center"
    >
      <Heading
        as="h2"
        fontSize={6}
      >
        404.
      </Heading>
      <Heading
        as="h3"
        fontWeight="normal"
        mt={2}
        fontSize={2}
      >
        Sajnos ez az oldalunk nem létezik.
      </Heading>
    </FlexCentered>

    <PageLayoutDefault
      heading="Friss cikkek"
    >
      <PostList
        posts={data.wp.latestPosts.nodes}
      />
      <ForwardLink
        as={Link}
        to="/oldal/2"
      >
        Még több cikk
      </ForwardLink>
    </PageLayoutDefault>
  </>
)

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundQuery {
    wp {
      latestPosts: posts(
        first: 10
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }
    }
  }
`
