import React from 'react'
import styled from '@emotion/styled'
import { LayoutSection } from '../layout'
import { Lazy, ForwardLink } from '../content'
import { Box, Placeholder, LinkExternalSafe } from '../ui'
import useSocialUrls from '../../hooks/useSocialUrls'
import useSiteMetadata from '../../hooks/useSiteMetadata'

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  height: 250px;
`

const WidgetYoutube = () => {
  const { youTube } = useSocialUrls()
  const { youtubeEmbedUrl } = useSiteMetadata()

  return (
    <LayoutSection heading="Youtube">
      <Box
        minHeight={250}
        variant="placeholder"
      >
        <Lazy
          fallback={(
            <Placeholder
              height={250}
            />
          )}
        >
          <StyledIframe
            allowFullScreen
            height="250"
            frameborder="0"
            src={youtubeEmbedUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </Lazy>
      </Box>
      <ForwardLink
        mt={2}
        display="flex"
        justifyContent="flex-end"
        target="_blank"
        href={youTube.url}
        as={LinkExternalSafe}
        rel="noopener noreferrer"
      >
        Összes videó
      </ForwardLink>
    </LayoutSection>
  )
}

export default WidgetYoutube
