import React from 'react'
import { Text } from '../ui'
import { LayoutSection } from '../layout'
import { EventDuration } from '../events'
import { PostListCompact } from '../posts'

const WidgetFutureEvents = ({ events = [] }) => (
  <LayoutSection heading="Jön">
    {
      events?.length > 0
        ? (
          <PostListCompact
            posts={(
              events.map((event) => ({
                ...event,
                meta: (
                  <EventDuration
                    {...event}
                    isAllDay={event.allDay}
                  />
                ),
              }))
            )}
          />
        ) : <Text>Nincsenek programok.</Text>
    }
  </LayoutSection>
)

export default WidgetFutureEvents
